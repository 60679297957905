import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '@layouts/business';
import SEO from '@components/seo';
import Container from '@components/Container';

import PostPreview from './PostPreview';
import styles from './styles.module.css';

export default function Blog() {
  const {
    allMarkdownRemark: { edges: data },
  } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
        edges {
          node {
            id
            excerpt(pruneLength: 300)
            frontmatter {
              date(formatString: "DD-MM-YYYY")
              path
              title
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="IMstream | Блог" />
      <main className={styles.main}>
        <Container>
          <h1 className={styles.title}>Блог</h1>
          <div className={styles.posts}>
            {data.map(
              ({
                node: {
                  excerpt: text,
                  frontmatter: { date, path, title },
                },
              }) => (
                <PostPreview
                  key={title}
                  title={title}
                  path={path}
                  text={text}
                  date={date}
                />
              )
            )}
          </div>
        </Container>
      </main>
    </Layout>
  );
}
