import React from 'react';
import { Link } from 'gatsby';
import styles from './styles.module.css';

export default function PostPreview({ title, date, text, path }) {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{title}</h2>
      <i className={styles.date}>{date}</i>
      <p className={styles.text}>{text}</p>
      <div className={styles.footer}>
        <Link className={styles.link} to={path}>
          Прочитать
        </Link>
      </div>
    </div>
  );
}
